import { Outlet } from 'react-router-dom';
import { Footer, Header } from 'containers/Layout';
import { LayoutContainer } from 'rhi-ui';

const MainLayout = () => (
  <>
    <Header />
    <LayoutContainer>
      <Outlet />
    </LayoutContainer>
    <Footer />
  </>
);

export default MainLayout;