import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Header as RHIHeader } from 'rhi-ui';
import TRentaLogoWhite from 'assets/images/TRentaLogoWhite.png';
import { useAuth } from 'contexts';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { last } from 'lodash-es';

const NAVIGATION_TABS = {
  dashboard: {
    value: 'dashboard',
    id: 'dashboard',
    label: 'Dashboard',
    disabled: false,
    exact: true
  },
  reportGeneration: {
    value: 'report-generation',
    id: 'report-generation',
    label: 'Report Generation',
    disabled: false,
    exact: true
  }
};

const Header = () => {
  // TODO: remove default value when actual value from URL is picked up
  // Possible solution - avoid redirect through /sign-in while reloading
  const [activeNavigationTab, setActiveNavigationTab] = useState(NAVIGATION_TABS.dashboard.value);

  const navigate = useNavigate();
  const location = useLocation();
  const { authorizedState, logout } = useAuth();

  const locationPathname = last(location.pathname.split('/').filter(Boolean));
  const defaultActiveKey = Object.values(NAVIGATION_TABS)?.find(({ path }) => path === locationPathname)?.path;

  useEffect(() => {
    setActiveNavigationTab(defaultActiveKey);
  }, [defaultActiveKey]);

  const handleNavigationTabChange = useCallback((event, path) => {
    navigate(path);
    setActiveNavigationTab(path);
  }, []);

  const userMenuItems = useMemo(() => ([{
    title: 'Logout',
    icon: LogoutIcon,
    onClick: logout
  }]));

  return (
    <RHIHeader
      username={authorizedState?.firstName}
      UserMenuProps={{
        menuItems: userMenuItems
      }}
      NavigationTabsProps={{
        tabs: Object.values(NAVIGATION_TABS),
        value: activeNavigationTab,
        defaultValue: activeNavigationTab,
        onChange: handleNavigationTabChange
      }}
      logo={(
        <img
          src={TRentaLogoWhite}
          alt="QTrack"
          style={{ height: '70%' }}
        />
      )}
    />
  );
};

Header.displayName = 'Header';

export default memo(Header);