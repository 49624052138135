import { memo } from 'react';
import { Box } from '@mui/material';
import ReportGenerationForm from './components/ReportGenerationForm';

const ReportGenerationPage = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100%"
    width="100%"
  >
    <ReportGenerationForm />
  </Box>
);

ReportGenerationPage.displayName = 'ReportGenerationPage';

export default memo(ReportGenerationPage);