import { memo } from 'react';
import { Grid, Paper } from '@mui/material';
import { InfoCard } from 'rhi-ui';
import {
  AddAssetButton,
  RegisterAssetButton,
  useAssetsSummary,
  useAvailableTrackers
} from 'containers/AssetManagement';

const DashboardAssetsInfo = () => {
  const {
    data: {
      assets_available_to_register_count = 0,
      assets_registered_count = 0
    } = {},
    isLoading = false
  } = useAssetsSummary();
  const {
    trackers: { tracker_available_to_pair = [] } = {}
  } = useAvailableTrackers();

  return (
    <Grid
      container
      columnSpacing={2}
      rowGap={2}
    >
      <Grid
        item
        xs={6}
        sm={4}
      >
        <InfoCard
          loading={isLoading}
          title="Registered Assets"
          primaryText={`${assets_registered_count} / ${assets_available_to_register_count + assets_registered_count}`}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={4}
      >
        <InfoCard
          loading={isLoading}
          title="Available Trackers"
          // TODO: fix InfoCard so it does not check for nullish values, but for undefined/null
          primaryText={tracker_available_to_pair.length || '0'}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
      >
        <Paper
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid container columnSpacing={{ sx: 0, md: 1 }}>
            <Grid
              item
              xs={12}
              md={6}
              mb={{ xs: 1, md: 0 }}
            >
              <AddAssetButton />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <RegisterAssetButton />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

DashboardAssetsInfo.displayName = 'DashboardAssetsInfo';

export default memo(DashboardAssetsInfo);