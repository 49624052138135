import React from 'react';
import { Box, Paper } from '@mui/material';
import MapDataTable from './components/MapDataTable';
import DashboardAssetsInfo from './components/DashboardAssetsInfo';

const DashBoardPage = () => (
  <Box
    height="100%"
    display="flex"
    flexDirection="column"
    width="100%"
  >
    <DashboardAssetsInfo />
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        mt: 2,
        overflow: 'hidden',
        minHeight: '200px'
      }}
      flex={1}
    >
      <MapDataTable />
    </Paper>
  </Box>
);

export default DashBoardPage;
